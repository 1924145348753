import React, { useEffect, useState } from "react";
import { FormData, FormField } from "../../../../../../util/formApi/FormAPI";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { Button, Grid, MenuItem, Modal, ModalBody, ModalHeader, Nav, Paper, Typography } from "@cbmisorg/material";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import traineeSetterModalCtrl from "./traineeSetterModal.controller";
import { App_info_Color, App_Light_Color } from "../../../../../../util/appStyle";
import Uploader from "../../../../../../../old/components/sharedUi/uploader/Uploader";
import { countryCodeTrainingTypeObj, objUploadType, objUserStatus, workSectorID } from "../../../../../../configuration/appVariables";
import appRouting from "../../../../../../configuration/routing/appRouting";
import { generatePath } from "react-router-dom";
import WorkSectorOption from "../regActivityGuest/component/WorkSectorOption.view";
import { encryptText, generatePatternID, modalOverFllow } from "../../../../../../util/appFunctions";
import { useNavigate } from "@cbmisorg/router";
import badgeimg from "../../../../../../util/assets/images/Picture1.png";
import Certificatesimg1 from "../../../../../../util/assets/images/certificatesimg1.jpg";
import Certificatesimg2 from "../../../../../../util/assets/images/certificatesimg2.jpg";
import caitQr from "../../../../../../util/assets/images/caitQr.jpg";

import { Avatar } from "@cbmisorg/client-app";
import { App_Server_Url_DownloadFiles } from "../../../../../../../old/appHelper/appVariables";
import { Icon } from "@cbmisorg/icons";

const labels = dictionary?.components?.stakeholders?.member?.account?.traineeSetter;

export default function TraineeSetterModalView({ parentState, isLoading, setIsLoading, blnIsStartUp = false, classes, blnPublic = false }) {
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
  const strUserTrainingTypeCode = appState?.userInfo?.strTrainingTypeCode || appState?.clientInfo?.clientCountryCode;
  const lang = appState?.clientInfo?.strLanguage;
  const [state, setState] = useState({
    blnOpenSetterModal: false,
    blnIsNew: true,
    blnOpenGovModal: false,
    lstIntroductoryVideo: [],
    blnHoverd: false,
    blnOpenImage: false,
    imgType: "",
  });
  const uploaderVideo = Uploader(state, setState, "lstIntroductoryVideo", "", {
    fileType: objUploadType.aaiot.id,
    blnIsMultiple: false,
    blnForceNewName: true,
    // file: dictionary?.components?.uploader?.receipt?.[lang],
    blnHoverd: false,
  });
  const fields = FormData({
    strFullName: { control: "text", validations: { required: true }, blnLanguage: false },
    bigMajorID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigDegreeID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigGender: { control: "list", validations: { required: true }, blnLanguage: false },
    bigEmploymentID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigCountryOfCitizenID: { control: "list", validations: { required: true }, blnLanguage: false },
    bigCountryOfResidenceID: { control: "list", blnLanguage: false }, //disabled

    // student
    strUniversityName: { control: "list", validations: {}, blnLanguage: false },
    bigWorkSector: {
      control: "list",
      blnLanguage: false,
      validations: {},
      options: [
        { key: workSectorID?.gov, value: dictionary?.shared?.app?.gov?.title, jsx: () => <WorkSectorOption type="gov" lang={lang} /> },
        { key: workSectorID?.prvt, value: dictionary?.shared?.app?.prvt?.title, jsx: () => <WorkSectorOption type="prvt" lang={lang} /> },
      ],
    },

    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: () => !appState?.clientInfo?.blnIsUserLoggedIn, email: !appState?.clientInfo?.blnIsUserLoggedIn },
    },
    strUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: { required: () => !appState?.clientInfo?.blnIsUserLoggedIn, minLength: !appState?.clientInfo?.blnIsUserLoggedIn ? 4 : false },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: { required: () => !appState?.clientInfo?.blnIsUserLoggedIn, minLength: !appState?.clientInfo?.blnIsUserLoggedIn ? 4 : false },
    },
  });

  const handlers = traineeSetterModalCtrl({ appDispatch, appState, isLoading, setIsLoading, blnIsStartUp });
  const updateTraineeInfo = handlers.updateTraineeInfo({ fields, setState, state, uploaderVideo });
  const onChangeCountry = handlers.onChangeCountry({ fields });
  const handelChangeEmployment = handlers.handelChangeEmployment({ fields });

  const goPath = () => {
    if (strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode && jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strIntLinkKw) {
      window.open(`${jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strIntLinkKw}`, "_blank");
    } else if (jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strIntLink) {
      window.open(`${jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strIntLink}`, "_blank");
    } else {
      let path = generatePath(appRouting?.Public_Initiative?.url, {
        strTrainingType: countryCodeTrainingTypeObj?.[appState?.userInfo?.jsnUserInfo?.strTrainingTypeCode || appState?.clientInfo?.clientCountryCode] || "kw",
      });
      window.open(`${path}`, "_blank");
    }
  };

  const goPathTms = () => {
    if (appState?.userInfo?.bigUserStatusID === objUserStatus.Approved) {
      const objInfo = {
        bigAppID: appState?.userInfo?.bigAppID,
        strUserEmail: appState?.userInfo?.strUserEmail,
        strUserPhone: appState?.userInfo?.strUserPhone,
        strUsername: appState?.userInfo?.strUserPhone,
        strFullName: appState?.userInfo?.jsnUserInfo?.strFullName,
        strPositionTitle: appState?.userInfo?.jsnUserInfo?.strPositionTitle,
        bigCountryOfCitizenID: appState?.userInfo?.jsnUserInfo?.bigCountryOfCitizenID,
        bigCountryOfResidenceID: appState?.userInfo?.jsnUserInfo?.bigCountryOfResidenceID,
        aaiotconf: generatePatternID(),
        blnGovID:
          strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode
            ? jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.blnMainContryGovID
            : jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.blnGovID,
      };
      window.open(
        `${
          strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode
            ? jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContrytmsLinkConfrm
            : jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strtmsLinkConfrm
        }&lang=${lang}&info=${encryptText(JSON.stringify(objInfo), "aaiot2025")}`,
        "_blank"
      );
    } else {
      window.open(
        `${
          strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode
            ? jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContrytmsLink
            : jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strtmsLink
        }&lang=${lang}&noenroll=${true}`,
        "_blank"
      );
    }
  };

  const goPdf = () => {
    let path = jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstBrochure?.length
      ? App_Server_Url_DownloadFiles + jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstBrochure?.[0]?.path
      : "https://static-media-project.s3.eu-central-1.amazonaws.com/aaiot/%D9%85%D8%A8%D8%A7%D8%AF%D8%B1%D8%A9+%D8%AA%D8%AF%D8%B1%D9%8A%D8%A8+1000+%D9%85%D8%A8%D8%B1%D9%85%D8%AC+%D9%83%D9%88%D9%8A%D8%AA%D9%8A.pdf";
    window.open(`${path}`, "_blank");
  };

  const navgateToPath = () => {
    let path = generatePath(appRouting?.TrainingRegistration?.url, {
      countrycode: strUserTrainingTypeCode || "kw",
    });
    navigate(`${path}`);
  };

  useEffect(() => {
    return () => {
      modalOverFllow();
    };
  }, []);

  const MunuNav = () => {
    const lstNav = [];
    if (jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.blnIsIntive) {
      lstNav.push(
        <MenuItem onClick={goPath} className={classes?.menuItem}>
          <Typography as="body3">{labels?.btnDetails?.[lang]}</Typography>
        </MenuItem>
      );
    }
    if (jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.blnIsTms) {
      lstNav.push(
        <MenuItem onClick={goPathTms} className={classes?.menuItem}>
          <Typography as="body3">{labels?.btntms?.[lang]}</Typography>
        </MenuItem>
      );
    }
    if (jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.blnIstBrochure) {
      lstNav.push(
        <MenuItem onClick={goPdf} className={classes?.menuItem}>
          <Typography as="body3">{labels?.brochure?.[lang]}</Typography>
        </MenuItem>
      );
    }
    if (jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.blnIsCirtf) {
      lstNav.push(
        <MenuItem
          className={classes?.menuItem}
          onClick={() => {
            setState({ ...state, blnOpenImage: true, imgType: "certificate" });
          }}
        >
          <Typography as="body3">{labels?.certificates?.[lang]}</Typography>
        </MenuItem>
      );
    }
    if (jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.blnIsGovEmp) {
      lstNav.push(
        <MenuItem
          className={classes?.menuItem}
          onClick={() => {
            setState({ ...state, blnOpenGovModal: true });
          }}
        >
          <Typography as="body3">{labels?.govEmp?.[lang]}</Typography>
        </MenuItem>
      );
    }

    return lstNav;
  };

  return {
    openEle: (
      <Paper className={classes?.bgBox2}>
        <Grid container justifyContent="center">
          <Grid item xs="12" md="12">
            <Typography as="subtitle1" className={classes?.mainTitle}>
              {strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode
                ? jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContryTitle?.[lang] || labels?.title?.[lang]
                : jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strTitle?.[lang] || labels?.title?.[lang]}
            </Typography>
          </Grid>
        </Grid>

        {strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode &&
        jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstSponserMain &&
        appState?.userInfo?.jsnUserInfo?.objUserStatusKey?.fullstack !== objUserStatus?.Rejected ? (
          <Grid container justifyContent="center" pb-5>
            <Grid item xs="12">
              <Avatar
                src={App_Server_Url_DownloadFiles + jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstSponserMain?.[0]?.path}
                alt={jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContrySponserName?.[lang]}
                sx={{ width: "100px", height: "100px" }}
              />
              <Grid item xs="12" md="12">
                <Typography as="subtitle2" className={classes?.mainTitle}>
                  {strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode
                    ? jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContrySponserName?.[lang] || ""
                    : jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strSponserName?.[lang] || ""}
                </Typography>
              </Grid>
              <Grid item xs="12" justifyContent="center">
                <Typography as="body3" color={"light"}>
                  {strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode
                    ? jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContrySponserPostion?.[lang] || ""
                    : jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strSponserPostion?.[lang] || ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        <Grid container justifyContent="center">
          <Grid item xs="12" md="12" className={classes?.descriptionLightTextContainer} key="trainingContainer" pb-5>
            <Typography
              as="body2"
              color={App_Light_Color}
              className={`${classes?.descriptionLightText} ${classes?.descriptionLightTextActive}`}
              sx={{ textAlign: "center !important" }}
              key="training"
            >
              {appState?.userInfo?.jsnUserInfo?.objUserStatusKey?.fullstack === objUserStatus?.Rejected
                ? strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode
                  ? jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContryDecAfterConf?.[lang] || labels?.description?.[lang]
                  : jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strDecAfterConf?.[lang] || labels?.description?.[lang]
                : strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode
                ? jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContryDecBeforeConf?.[lang] || labels?.description?.[lang]
                : jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strDecBeforeConf?.[lang] || labels?.description?.[lang]}
            </Typography>
          </Grid>
          {(jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContryDate?.[lang] && strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode) ||
          jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strDate?.[lang] ? (
            <Grid item xs="12" container justifyContent="center">
              <Grid item>
                <Typography as="body2" color="light">
                  {labels?.startDate?.[lang]}
                </Typography>
              </Grid>
              <Grid item>
                <Typography as="subtitle2" color="light">
                  {appState?.userInfo?.jsnUserInfo?.objUserStatusKey?.fullstack === objUserStatus?.Rejected
                    ? null
                    : strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode
                    ? jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strMainContryDate?.[lang]
                    : jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.strDate?.[lang]}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          <Grid item>
            <Button
              label={<Typography as="body3">{appState?.userInfo?.blnIsStudent ? labels?.edit?.[lang] : labels?.btn?.[lang]}</Typography>}
              mode="outlined"
              color={App_info_Color}
              onClick={navgateToPath}
              className={classes?.btnBox}
              size="sm"
              icon={appState?.userInfo?.blnIsStudent ? "edit" : "PersonAdd"}
            />
          </Grid>

          {appState?.userInfo?.bigUserStatusID === objUserStatus.Approved ? (
            <Grid item>
              <Button
                label={<Typography as="body3">{labels?.btntms?.[lang]}</Typography>}
                mode="outlined"
                color={App_info_Color}
                onClick={goPathTms}
                className={classes?.btnBox}
                size="sm"
                icon={"ModelTraining"}
              />
            </Grid>
          ) : (
            <Grid item>
              <Nav className={classes?.navBtton} blnCloseOutMenuClick={true} menu={MunuNav()}>
                <Icon icon="info" m-auto size="18px" px-2 />
                <Typography as="caption" m-auto>
                  {labels?.trainingInfo?.[lang]}
                </Typography>
              </Nav>
            </Grid>
          )}
        </Grid>
      </Paper>
    ),

    modal: (
      <Modal
        open={state.blnOpenSetterModal}
        eventClose={() => {
          modalOverFllow();
          setState({ ...state, blnOpenSetterModal: false });
        }}
        className={classes?.modal}
      >
        <ModalHeader className={classes?.modalHeader}>
          <Typography as="subtitle2" color={App_info_Color} className={classes?.labelModal}>
            {labels?.title?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12}>
              <FormField objHandler={fields.strFullName} icon="person" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="12">
              <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" mode={"classic"} color={App_info_Color} className={classes?.field} onChange={onChangeCountry} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigGender} icon="Wc" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigEmploymentID} icon="work" mode={"classic"} color={App_info_Color} className={classes?.field} onChange={handelChangeEmployment} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigDegreeID} icon="documentScannerRounded" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            <Grid item xs={12} sm="6">
              <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"} color={App_info_Color} className={classes?.field} />
            </Grid>
            {handlers?.objCat?.Root?.employment?.student?.id === fields.bigEmploymentID?.getValue()?.key ? (
              <React.Fragment>
                <Grid item xs={12} sm="12">
                  <FormField objHandler={fields.strUniversityName} icon="accountBalance" mode={"classic"} color={App_info_Color} className={classes?.field} />
                </Grid>
              </React.Fragment>
            ) : handlers?.objCat?.Root?.employment?.unemployed?.id !== fields.bigEmploymentID?.getValue()?.key ? (
              <React.Fragment>
                <Grid item xs={12} sm="12">
                  <FormField objHandler={fields.bigWorkSector} icon="Work" mode={"classic"} color={App_info_Color} className={classes?.field} />
                </Grid>
              </React.Fragment>
            ) : null}
          </Grid>
          {!jsnSystemConfig?.objTrainingSponserConfig?.fullstack?.lstContryTrainingReqVideoCode?.includes(strUserTrainingTypeCode) ? null : (
            <React.Fragment>
              <Grid item xs="12" container justifyContent="center" py-3>
                <Grid item>
                  <Typography as="body3" className={classes?.descriptionLightText}>
                    {labels?.uploadVideo?.[lang]}
                  </Typography>
                </Grid>
                <Grid item alignSelf="baseline" alignContent="baseline" py-0>
                  {uploaderVideo?.JSX}
                </Grid>
              </Grid>
            </React.Fragment>
          )}

          {appState?.clientInfo?.blnIsUserLoggedIn ? null : (
            <Grid container spacing={3} pt-0>
              <Grid item className={classes?.subtitle}>
                <Typography as="subtitle1" className={classes?.subtitleLabel}>
                  {dictionary?.components?.public?.regActivityGuest?.registrationInfo?.[lang]}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormField
                  objHandler={fields.strUserEmail}
                  icon="email"
                  mode={"classic"}
                  iconColor={App_info_Color}
                  color={App_info_Color}
                  inputDir="ltr"
                  className={classes?.field}
                />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strUserPassword} icon="vpnkey" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strConfirmPassword} icon="vpnkey" mode={"classic"} iconColor={App_info_Color} color={App_info_Color} className={classes?.field} />
              </Grid>
            </Grid>
          )}

          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item xs="12" pt-8 pb-5>
              <Button
                py-0
                px-10
                label={dictionary?.shared?.buttons?.submitBtn?.[lang]}
                color={App_info_Color}
                onClick={updateTraineeInfo}
                mode="outlined"
                className={classes?.btnBox}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),

    modalImage: (
      <Modal
        open={state.blnOpenImage}
        eventClose={() => {
          modalOverFllow(false);
          setState({ ...state, blnOpenImage: false, imgType: "" });
        }}
        className={classes?.modal}
      >
        <ModalHeader>
          <label style={{ color: "#fff" }}>{labels?.certificatesSamples?.[lang]}</label>
        </ModalHeader>
        <ModalBody>
          {state?.imgType === "badge" ? (
            <Grid container justifyContent="center">
              <Grid item>
                <img src={badgeimg} alt="" style={{ height: "100%", maxHeight: "80vh", maxWidth: "90%", position: "relative", margin: "auto" }} />
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent="center">
              <Grid item xs="12" pb-5>
                <img src={Certificatesimg1} alt="" style={{ height: "100%", maxHeight: "80vh", maxWidth: "90%", position: "relative", margin: "auto" }} />
              </Grid>
              <Grid item xs="12">
                <img src={Certificatesimg2} alt="" style={{ height: "100%", maxHeight: "80vh", maxWidth: "90%", position: "relative", margin: "auto" }} />
              </Grid>
            </Grid>
          )}
        </ModalBody>
      </Modal>
    ),
    modalGovCait: (
      <Modal
        open={state.blnOpenGovModal}
        eventClose={() => {
          modalOverFllow(false);
          setState({ ...state, blnOpenGovModal: false });
        }}
        className={classes?.modal}
      >
        <ModalHeader>
          <label style={{ color: "#fff" }}>{labels?.govEmp?.[lang]}</label>
        </ModalHeader>
        <ModalBody>
          <Grid container justifyContent="center">
            <Grid item xs="12" justifyContent="center">
              <Typography as="body1" className={classes?.labelModal} color={App_Light_Color}>
                {labels?.govNote?.[lang]}
              </Typography>
            </Grid>
            <Grid item xs="8" sm="6" md="4" lg="3">
              <img src={caitQr} alt="qrCode" width="90%" />
            </Grid>
            <Grid item xs="12" justifyContent="center">
              <Button
                label={labels?.govBtn?.[lang]}
                className={classes?.btnBox}
                color={App_info_Color}
                mode="outlined"
                onClick={() => {
                  window?.open(
                    "https://elearning.cait.gov.kw/elearning/SSOClassSurvey/Landing.aspx?DestPage=ContentPages3.aspx?loadingpage=CAITconferenceEventCalender.aspx?mid=36&Company=CAIT",
                    "_blank"
                  );
                }}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    ),
  };
}
