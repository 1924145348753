import { Button, Grid, Paper, Typography } from "@cbmisorg/material";
import React, { useState } from "react";
import { useApp } from "../../../../../../configuration/contextapi/context";
import { dictionary } from "../../../../../../util/appLanguage/dictionary";
import { App_info_Color } from "../../../../../../util/appStyle";
import robot7 from "../../../../../../util/assets/images/robot7.png";
import robot8 from "../../../../../../util/assets/images/robot8.png";
import robot6 from "../../../../../../util/assets/images/robot6.png";
import { Icon } from "@cbmisorg/icons";

const labels = dictionary?.components?.stakeholders?.member?.account?.runningActivity;
function AaaiotInfoView({ isLoading, setIsLoading, parentState, setParentState, classes }) {
  const { appState } = useApp();
  const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
  const strUserTrainingTypeCode = appState?.userInfo?.strTrainingTypeCode;
  const lang = appState?.clientInfo?.strLanguage;
  const [state, setState] = useState({ blnHoverd: false });

  const openWindow = (path) => () => {
    if (typeof window !== "undefined" && path) {
      window.open(path, "_blank");
    }
  };

  return {
    lstElm: (
      <React.Fragment>
        <Paper className={classes?.bgBox2} p-0>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item key="aaiotInfo">
              <Typography
                as="body1"
                // className={`${classes?.descriptionLightText}`}
                key="aaiot"
                color={App_info_Color}
              >
                {strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode
                  ? jsnSystemConfig?.strDetailsAAiotDefaultContryAccount?.[lang]
                  : jsnSystemConfig?.strDetailsAAiotAccount?.[lang]}
              </Typography>
              <Grid container justifyContent="center" className={`${classes?.arrowClose} arrowClose`} key="arrowClose_training">
                <Grid item xs="auto" md="0" my-2 key="arrowClose_training_item">
                  <Icon icon="KeyboardArrowUp" size="30px" color={App_info_Color} onClick={() => {}} key="arrowClose_icon" />
                </Grid>
              </Grid>
            </Grid>
            {!jsnSystemConfig?.strDetailsAAiotAccountLink ? null : (
              <Grid item xs="12" pb-3>
                <Button
                  label={labels?.btnDetails?.[lang]}
                  mode="outlined"
                  color={App_info_Color}
                  className={classes?.btnBox}
                  onClick={strUserTrainingTypeCode === jsnSystemConfig?.strDefaultContryRegisterCode? openWindow(jsnSystemConfig?.strDetailsAAiotDefaultContryAccountLink):openWindow(jsnSystemConfig?.strDetailsAAiotAccountLink)}
                />
              </Grid>
            )}
          </Grid>
          <img src={robot6} alt="" className={classes?.robotImg2}  />
        </Paper>
      </React.Fragment>
    ),
  };
}

export default AaaiotInfoView;
